import React, {useState} from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const MainNavigation = ({theme}) => {
    const [isOpen, setOpen] = useState(false);
    const [isClosing, setClosing] = useState(false);
    const updateClosing = (isOpen) => {
        if(isClosing)
        {
            clearTimeout(isClosing)
            setClosing(false)   
        }

        if(!isOpen) {
            setClosing(setTimeout(() => {
                setClosing(false)     
            }, 250))    
        }
    }
    return <nav className={`
                    main-navigation 
                    ${ isOpen ? 'main-navigation--open' : '' } 
                    ${ isClosing ? 'main-navigation--closing' : '' } 
                    ${(typeof theme !== "undefined" ? "main-navigation--" + theme : "" )}`}>
        <button className={`
                btn btn--transparent 
                main-navigation__mobile-toggle 
                hamburger 
                hamburger--emphatic 
                ${isOpen ? 'is-active' : ''}
            `} 
            onClick={() => {setOpen(!isOpen); updateClosing(!isOpen)}}>
        <span className="hamburger-box">
            <span className="hamburger-inner"></span>
        </span>
        </button>
        <ul>
            <li>
                <AniLink duration={.5} fade to="/services/">Services</AniLink>
            </li>
            <li>
                <AniLink duration={.5} fade to="/portfolio/">Portfolio</AniLink>
            </li>
            <li>
                <AniLink duration={.5} fade to="/about-us/">About Us</AniLink>
            </li>
            <li>
                <a href="https://codewave.eu/blog/" target="_blank" rel="noopener noreferrer">Blog</a>
            </li>
            <li><AniLink duration={.5} fade className="btn btn--transparent btn--small" to="/contact/">Contact Us</AniLink></li>
        </ul>
    </nav>
}

export default MainNavigation