
import React, {useState, useEffect} from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const CookieBar = ({onChange}) => {
    const [isVisible, setVisible] = useState(undefined)

    useEffect(() => {
        setVisible(!localStorage.cookieAccepted)
      }, [])

    useEffect(() => {
        if(typeof onChange === "function") {
            onChange(isVisible);
        }
      }, [isVisible, onChange])
    
    return isVisible ? ( <div className="popup cookie-bar">
        <button onClick={() => {setVisible(false)}} className="popup__close">&times;</button>
        <div className="cw-container">
            <span className="cookie-bar__text">
                In order to provide you with best possible experience, we may store cookies on your computer. 
                To learn more, please refer to our <AniLink duration={.5} fade to="/cookie-policy/">
                    Cookie&nbsp;Policy
                </AniLink> and <AniLink duration={.5} fade to="/privacy-policy/">
                    Privacy&nbsp;Policy
                </AniLink>.
            </span>
            <span className="cookie-bar__agreement">
                <button onClick={() => {localStorage.cookieAccepted = true; setVisible(false)}} className="cookie-bar__button">
                    <span>I understand</span>
                </button>
            </span>
        </div>
    </div> ) : null
}

export default CookieBar

