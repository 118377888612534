/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import Header from "./header"
import Popups from "./popups"
import Footer from "./footer"
import Announcement from "./announcement"
import "../scss/main.scss"

const Layout = ({children, theme}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
            announcement: allCodewaveAnnouncement(limit: 1, filter: {isPublic: {eq: true}}, sort: {fields: flotiqInternal___createdAt, order: DESC}) {
                nodes {
                    content
                }
            }
        }
    `)

    return (
        <>
            <Announcement text={data.announcement.nodes[0] && data.announcement.nodes[0].content}/>
            <Header siteTitle={data.site.siteMetadata.title} theme={theme} offsetTop={0} />
            <main className="site-main">{children}</main>
            <Popups />
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
