import React from "react"
import {Link} from "gatsby";
import Facebook from "../images/svg/social-icons/facebook.svg"
import LinkedIn from "../images/svg/social-icons/linkedin.svg"
import Twitter from "../images/svg/social-icons/twitter.svg"
import Github from "../images/svg/social-icons/github.svg"
import CodeWaveFooterLogo from "../images/svg/logo/logo_codewave_white_horizontal.svg"

const Footer = () => (
    <footer className="footer">
        <div className="footer-logo">
            <CodeWaveFooterLogo/>
        </div>
        <section className="footer__main-section">
            <div className="footer-contact-details-column">
                <h2 className="footer-column-header">
                    Contact
                </h2>
                <div className="contact-details-block">
                    <p>
                        <strong>CodeWave LLC</strong><br/>
                        16192 Coastal Hwy, Lewes,<br/>
                        DE 19958, united States<br/>
                        phone: <a href="tel:0016319095771">+1 (631) 909 5771</a>
                    </p>
                    <p>
                        <strong>CodeWave Sp. z o.o.</strong><br/>
                        Krakowska 88a, Wroclaw<br/>
                        52-129, Poland<br/>
                        phone: <a href="tel:0048717391391">+48 717 391 391</a>
                    </p>
                </div>
            </div>
            <div className="footer-menu-column">
                <div className="footer-menu-column__single-menu">
                    <h2 className="footer-column-header">
                        Services
                    </h2>
                    <ul>
                        <li><Link to="/">Custom Systems</Link></li>
                        <li><Link to="/">Creative Support</Link></li>
                        <li><Link to="/">Cloud Solutions</Link></li>
                        <li><Link to="/">AWS Consulting</Link></li>
                        <li><Link to="/">Serverless</Link></li>
                        <li><Link to="/">Web Development</Link></li>
                    </ul>
                </div>
                <div className="footer-menu-column__single-menu">
                    <h2 className="footer-column-header">
                        Technology
                    </h2>
                    <ul>
                        <li><Link to="/">Java</Link></li>
                        <li><Link to="/">PHP</Link></li>
                        <li><Link to="/">Microsoft .NET Core</Link></li>
                        <li><Link to="/">Technology</Link></li>
                        <li><Link to="/">Tech</Link></li>
                        <li><Link to="/">Web Development</Link></li>
                        <li><Link to="/">Custom Systems</Link></li>
                        <li><Link to="/">Creative Support</Link></li>
                    </ul>
                </div>
                <div className="footer-menu-column__single-menu">
                    <h2 className="footer-column-header">
                        Platforms
                    </h2>
                    <ul>
                        <li><Link to="/">iOS</Link></li>
                        <li><Link to="/">Android</Link></li>
                        <li><Link to="/">Windows</Link></li>
                        <li><Link to="/">Hybrid Platform</Link></li>
                        <li><Link to="/">Azure</Link></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="footer__bottom-section">
            <div className="footer-bottom-section-menu">
                <ul>
                    <li>
                        <a href="https://codewave.eu/image/0x0/809c35de-629d-11e9-b4fa-be16a8067e3d.pdf" target="_blank">Cookie policy</a>
                    </li>
                    <li>|</li>
                    <li>
                        <a href="https://codewave.eu/image/0x0/809c37b1-629d-11e9-b4fa-be16a8067e3d.pdf" target="_blank">Privacy policy</a>
                    </li>
                </ul>
                <p className="copyright-text">
                    © CodeWave. All rights reserved 2020.
                </p>
            </div>
            <div className="footer-social-links">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/codewave.eu/" target="_blank" rel="noopener noreferrer"><Facebook/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/code_wave" target="_blank" rel="noopener noreferrer"><Twitter/></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/codewave-sc" target="_blank" rel="noopener noreferrer"><LinkedIn/></a>
                    </li>
                    <li>
                        <a href="https://github.com/cdwv" target="_blank" rel="noopener noreferrer"><Github/></a>
                    </li>
                </ul>
            </div>
        </section>
    </footer>
)

export default Footer
