import React, {useState, useEffect} from "react"
import CokieBar from "./cookie-bar"
import ConsultationBox from "./consultation-box"
const Popups = () => {
    const [isCookieVisible, setCookieVisible] = useState(true);

    return <div className="popups-container">
        <ConsultationBox showOnMobile={!isCookieVisible} />
        <CokieBar onChange={setCookieVisible} />
    </div>
}

export default Popups