/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

const Announcement = ({text}) => {
    return (
        text 
        ?
        <div className="announcement">
            <div className="announcement__bar" dangerouslySetInnerHTML={{__html: text}}></div>
            <div className="announcement__bar announcement__bar--hidden-clone" dangerouslySetInnerHTML={{__html: text}}></div>
        </div> 
        : 
        null
    )
}

export default Announcement
