import React, {useState} from "react"
const ConsultationBox = ({showOnMobile}) => {
    const [isVisible, setVisible] = useState(true);

    return isVisible ? (<div className={`popup consultation-box ${!showOnMobile && 'consultation-box--mobile-hidden'}`}>
        <button onClick={() => {setVisible(false)}} className="popup__close">&times;</button>
        <p>We love talking to our clients and potential clients. Feel free to schedule a call with us and tell us about your problems - we'll try to help!</p>
        <a href="https://meet.simplyscale.io/15min-aws-lambda" target="_blank" className="btn btn--small">Schedule a Free Consultation</a>
    </div>) : null
}

export default ConsultationBox