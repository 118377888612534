import React from "react"
import CodeWaveLogo from "../images/svg/logo/logo_codewave_navy_horizontal.svg"
import MainNavigation from "../components/main-navigation"
import AniLink from "gatsby-plugin-transition-link/AniLink"
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuScrollPositionClass: "", 
            menuScrollDirectionClass: "", 
            resetTheme: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleScroll () {
        if(typeof window === "undefined") return;
        if(window.scrollY > 300) {
            this.setState({
                menuScrollPositionClass: "header--collapsed",
                resetTheme: true
            })
        } else {
            this.setState({
                menuScrollPositionClass: "",
                resetTheme: false
            })
        }

        if(this.lastScrollPosition < window.scrollY) {
            this.setState({
                menuScrollDirectionClass: "header--scroll-down",
            })
        } else {
            this.setState({
                menuScrollDirectionClass: "header--scroll-up",
            })
        }
        this.lastScrollPosition = window.scrollY;        
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }
    componentWillUnmount () {
        window.removeEventListener("scroll", this.handleScroll)
    }
    render() {
        let headerClass = this.state.menuScrollPositionClass 
            + " " + this.state.menuScrollDirectionClass 
            + " " + (typeof this.props.theme !== "undefined" && !this.state.resetTheme ? "header--" + this.props.theme : "" )
        return <header className={headerClass}>
            <div className="header__navigation">
                <div className="logo">
                    <AniLink duration={.5} fade to="/">
                        <CodeWaveLogo/>
                    </AniLink>
                </div>
                <MainNavigation theme={!this.state.resetTheme && this.props.theme}/>
            </div>
        </header>
    }
}

export default Header
